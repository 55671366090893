import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { withRouter } from 'react-router'
import reactHtmlReplace from 'react-html-replace';
import styles from "./styles.module.css";

import { FaPhone } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaGlobeAmericas } from 'react-icons/fa';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaRegClock } from 'react-icons/fa';

import WatermerkImage from './pictures/WatermerkImage.jpg';

class ContactComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        items:  [               
                {
                    id: 'contact',
                    image: WatermerkImage,
                    title: 'CONTACT MET BUREAUMB',
                    content: [
                                { 
                                    title: '',
                                    content: 'Met <bureaumb></bureaumb> hebt u direct en persoonlijk contact. Wij zien de vraag achter uw vraag, ontrafelen de kluwen en brengen uw opgave terug tot de essentie. Met u bepalen we de perspectieven en maken we doelen concreet. Onze kennis en ervaring zetten we in voor uw resultaat. Wij zijn van maandag tot en met vrijdag telefonisch bereikbaar tussen 08:30 uur - 17:30 uur'
                                },
                                {
                                    title: 'Contactgegevens',
                                    content: ' <FaPhone></FaPhone> 06-22204798  <nl></nl><FaEnvelope></FaEnvelope> info@bureaumb.nl <nl></nl> <FaGlobeAmericas></FaGlobeAmericas> www.bureaumb.nl  <nl></nl><FaMapMarkerAlt></FaMapMarkerAlt> Blazoenlaan 1, 5411 DB Zeeland  <nl></nl><FaRegClock></FaRegClock> 08:30 - 17:30',
                                }
                        ],
                },
                ]
    };
    }
    
    componentWillMount(){
        console.log(this.state.items[0].image)
    }

    render() { 
            if(this.state.items.filter(x => x.id === this.props.match.params.id)){
                return (
                    <Container className={styles.container}>
                                            {this.state.items.map(item => {
                            if(item.id === this.props.match.params.id)
                                return  (
                    <Row>
                        <Col>
                            <img
                                className={styles.image}
                                src={item.image}
                                alt="Een foto van de pronvincie met uitleg over hoe een provincie werkt"
                                    />
                        </Col>
                        <Col>

                                    <div key={item.key}>
                                    <div className={styles.title}>{item.title}</div> 
                                    <br />

                                    {item.content.map((subItem, index) => (
                                        <div key={index}>
                                            <h2 className={styles.subTitle}>{subItem.title}</h2>

                                            {/* <div className={styles.content}>{subItem.content.split('_n_').map((item, key) => {
                                                return <span key={key}>{item}<br /></span>
                                            })}</div> */}

                                            <div className={styles.content}>
                                                <span>{reactHtmlReplace(
                                                    subItem.content,
                                                    (tag, props) => {
                                                        if (tag === 'nl') {
                                                            return <div className={styles.newLine} />;
                                                        }
                                                        if (tag === 'p') {
                                                            return <p />;
                                                        }
                                                        if (tag === 'italic') {
                                                            return <i />;
                                                        }
                                                        if (tag === 'bureaumb') {
                                                            return <span>Bureau<span className={styles.bureaumb}>MB</span></span>;
                                                        }
                                                        if (tag === 'link') {
                                                            const { tag, href, style } = props;
                                                            return <a href={'#' + href} className={style}>{`${tag}`}</a>;
                                                        }
                                                        if (tag === 'FaPhone') {
                                                            return <FaPhone style={{ fill: '#364B9A' }} />;
                                                        }
                                                        if (tag === 'FaEnvelope') {
                                                            return <FaEnvelope style={{ fill: '#364B9A' }} />;
                                                        }
                                                        if (tag === 'FaGlobeAmericas') {
                                                            return <FaGlobeAmericas style={{ fill: '#364B9A' }} />;
                                                        }
                                                        if (tag === 'FaMapMarkerAlt') {
                                                            return <FaMapMarkerAlt style={{ fill: '#364B9A' }} />;
                                                        }
                                                        if (tag === 'FaRegClock') {
                                                            return <FaRegClock style={{ fill: '#364B9A' }} />;
                                                        }
                                                    }
                                                    )}<br /></span>
                                        </div>
                                            <br />

                                        </div>

                                    ))}
                                    </div>
                                    

                        </Col>
                    </Row>
                    )})}
                    </Container>
                );
            }else{
                return (
                    <Container fluid="md">
                    <Row>
                        <Col lg={true} >
                        <div>U heeft een foute URL opgegeven. Klik op het BureaMB Logo om terug te gaan.</div>
                        </Col>
                    </Row>
                    </Container>
                )
            }
    }
}
 
export default withRouter(ContactComponent);