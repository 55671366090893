import React, { Component } from 'react';
import MBLogo from './pictures/MBLogo.png'

import { Link } from "react-router-dom";

import Media from 'react-bootstrap/Media'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import styles from "./styles.module.css"
import { Col } from 'react-bootstrap';

import Image from 'react-bootstrap/Image'

class LogoComponent extends Component {
    
    render() { 
        return (  
            <div>
            <Container fluid>         
                <Row className="justify-content-md-center">           
                <Col md="auto">
                    <Link to="/">
                        <div>
                            <Image className={styles.padding} src={MBLogo} alt="Een brug met een blauw bolletje aan de rechterkant. Onder de brug staat bureamnb" /> 
                        </div>
                    </Link>
                    </Col>
                </Row>
            </Container>
            </div>
            
        );
    }
}
 
export default LogoComponent;