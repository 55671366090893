import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    HashRouter ,
  } from "react-router-dom";

import NavbarComponent from './Components/navbar/Navbar';
import AppbarComponent from './Components/appbar/Appbar';
import CarouselComponent from './Components/carousel/Carousel';
import LogoComponent from './logo/logo';
import FooterComponent from './Components/footer/Footer';
import ArticleComponent from './Components/article/Article';

import VraagStukkenComponent from './pages/vraagstukken/Vraagstukken';
import ContactComponent from './pages/contact/Contact';
import BureaumbComponent from './pages/bureaumb/Bureaumb';
import DienstenComponent from './pages/diensten/Diensten';
import SectorenComponent from './pages/sectoren/Sectoren';

class HomeScreen extends React.Component {
  
    componentWillMount(){
      document.title = "Organisatie adviesbureau BureauMB"
  }

    render() { 
        return ( 

        <HashRouter>
            <div>
            <AppbarComponent /> 
            <LogoComponent />
            <NavbarComponent />
            </div> 
        
        <Switch>
        <Route exact path="/sectoren/:id" render={(props) => <ProvincesFunction {...props} /> }  />

        <Route exact path="/vraagstukken/:id" render={(props) => <VraagstukkenFunction {...props} /> }  />

        <Route exact path="/diensten/:id" render={(props) => <DienstenFunction {...props} /> }  />

        <Route exact path="/bureaumb/:id" render={(props) => <BureaumbFunction {...props} /> }  />

        <Route exact path="/contact/:id" render={(props) => <ContactFunction {...props} /> }  />



        {/* <Route path="/contact/:id" render={(props) => <BureaumbFunction {...props} /> }  /> */}

          <Route exact path="/">
            <CarouselFunction/>
            <br />
            <ArticleComponent />
            <br />
            <br />
          </Route>
          <Route path="*" component={NoMatchFound} />
          <Route path="/404" component={NoMatchFound} />
        </Switch>

        <FooterComponent />
        </HashRouter>
        );
    }
}

function NoMatchFound(){
  return (
    <div>no match found</div>
  )
}

function CarouselFunction(){
    return <CarouselComponent />
}

function ProvincesFunction() {
    return <SectorenComponent />
}

function VraagstukkenFunction() {
  return <VraagStukkenComponent />
}

function DienstenFunction() {
  return <DienstenComponent />
}

function BureaumbFunction() {
  return <BureaumbComponent />
}

function ContactFunction() {
  return <ContactComponent />
}

// function ContactFunction() {
//   return <ContactComponent />
// }
 
export default HomeScreen;