import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { FaLinkedinIn } from "react-icons/fa";

import LinkedinLogo from './pictures/Linkedin.png';

import styles from './styles.module.css';

const style = {

}

class FabComponent extends Component {
    state = {  }
    render() { 
        return ( 
            <div>
                <a href="https://www.linkedin.com/company/bikx">
                    <img className={styles.fab}  src={LinkedinLogo} alt="Een brug met een blauw bolletje aan de rechterkant. Onder de brug staat BureauMB" /> 
                </a>
            </div>

        );
    }
}
 
export default FabComponent;