import React, { Component } from 'react';

import styles from "./styles.module.css";


import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';

class CarouselComponent extends Component {
    constructor(props) {
        super(props);
            this.state = {
              
                carouselItems:  [
                    {
                        title: 'Voor wie werken wij?',
                        picture: 'https://i.imgur.com/gDHgrpp.jpg',
                        content: [
                             "BureauMB werkt voor het decentrale openbaar bestuur. Wij richten ons op provincies, gemeenten, waterschappen en op de gemeenschappelijke regelingen(organisaties) die zij met elkaar aangaan."
                        ],
                     },
                     {
                        title: 'Wat doen wij?',
                        picture: 'https://i.imgur.com/gzPyCdc.jpg',
                        content: [
                            "BureauMB is een bureau van inspirerende deskundigen die u en uw organisatie ondersteunen op het pad van de vooruitgang.",
                            "Wij gaan voor goede verbindingen met onze klanten want binding is de motor achter hoogwaardige dienstverlening. Wij zijn een flexibele en een creatieve netwerkorganisatie van adviseurs, coaches, trainers en managers die permanent meebewegen met de samenleving en het openbaar bestuur."
                        ],
                     },
                    {
                        title: 'Welke vraagstukken pakken wij aan?',
                        picture: 'https://i.imgur.com/3KGTJI5.jpg',
                        content: [
                             "BureauMB is uw sparringspartner en helpende hand bij strategische-,bestuurskundige en organisatiekundige vraagstukken.",
                             "Met onze kennis kunnen wij u helpen als u opnieuw de strategie gaat bepalen, gaat samenwerken met andere organisaties, uw bestuurlijke organisatie wilt laten functioneren, uw ambtelijke organisatie wilt versterken of professionaliteit van uw mensen wilt vergroten. zijn een flexibele en een creatieve netwerkorganisatie van adviseurs, coaches, trainers en managers die permanent meebewegen met de samenleving en het openbaar bestuur."
                        ],
                     },
                     {
                        title: 'Wie zijn wij?',
                        picture: 'https://i.imgur.com/pWcMCOH.jpg',
                        content: [
                            "BureauMB ondersteunt volksvertegenwoordigers, bestuurders, managers en ambtenaren, en hun organisaties bij het versterken van hun performance en ontwikkeling.",
                            "Met onze diensten professionaliseren wij individuen en teams, en verbeteren wij de performance van organisaties. Wij leveren u kennis, trainen u op vaardigheden, coachen u in uitdagende situaties, adviseren u bij verandertrajecten en managen uw opgave naar de gewenste resultaat."                        ],
                     },
                     {
                        title: 'Wat mag u van ons verwachten?',
                        picture: 'https://i.imgur.com/gDHgrpp.jpg',
                        content: [
                             "BureauMB geeft u de volle aandacht. Onze deskundigen nemen hun vak serieus. Zij weten welke vraagstukken u bezig houden. Zij weten dat het succes uit vijf componenten bestaat: durven, lef tonen, hard werken, tegenslagen overwinnen en doorgaan waar anderen ophouden.",
                             " Bij iedere opdracht zorgen wij voor een scherp inzicht in uitgangspunt, doel en traject. Onderweg monitoren we de voortgang en sturen we bij. Wij zijn scherp op de sociale context en verliezen nooit de menselijke maat uit het oog."
                        ],
                     },
                ]
        };
      } 


    render() { 
        return ( 
            <Container>
                <Row className={styles.carouselPadding}>
                        <Carousel 
                        interval={20000}
                        controls={false}
                        pause={'hover'}
                        wrap={false}>
                        {this.state.carouselItems.map(item => (
                            
                            <Carousel.Item key={item.id}>
                            <img
                                className="d-block w-100"
                                src={item.picture}
                                alt="First slide"
                            />
                            <div className={styles.carouselCaptation}>
                                <h2>{item.title}</h2>

                                {item.content.map((subItem, index) => (

                                    <p key={index}>
                                    {subItem}
                                    </p>
                                ))}
                                <div>
                                    <Button variant="dark"> Lees meer! </Button>
                                </div>
                            </div>
                            </Carousel.Item>
                        ))}
                        </Carousel> 
                </Row>
        </Container>
          );
    }
}
 
export default CarouselComponent;