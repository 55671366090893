import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { withRouter } from 'react-router'

import GemeenteImage from "./pictures/Gemeente.jpg";
import ProvinciesImage from "./pictures/Provincies.jpg";
import GemeenschappelijkeorganisatiesImage from "./pictures/Gemeenschappelijkeorganisaties.jpg";
import MaatschappelijkeorganisatiesImage from "./pictures/Maatschappelijkeorganisaties.jpg";
import SectorenImage from "./pictures/Sectoren.png";
import WaterschappeInmage from "./pictures/Waterschappen.jpg";

import reactHtmlReplace from 'react-html-replace';

import styles from "./styles.module.css"

class SectorenComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        items:  [         
                {
                    id: 'sectoren',
                    image: SectorenImage,
                    title: 'DE SECTOREN',
                    content: [
                                { 
                                    title: 'Openbaar bestuur',
                                    content: 'Het openbaar bestuur is het geheel van mensen en organisaties dat zich bezighoudt met het besturen van de Nederlandse staat. Het binnenlands bestuur bestaat uit drie kernlagen: rijk, <link href="/sectoren/provincies" tag="provincies"></link> en <link href="/sectoren/gemeenten" tag="gemeenten"></link>. Daarnaast kennen we de bestuurslaag van de waterschappen en in de grote steden bestaat een extra laag in de vorm van deelgemeenten.'
                                },
                                {
                                    title: 'Centraal openbaar bestuur',
                                    content: 'Het rijk is de centrale overheid bestaande uit een bestuurlijk apparaat en een ambtelijk apparaat. Het bestuurlijke apparaat bestaat uit het parlement (volksvertegenwoordiging verdeeld in twee kamers) en de regering (dagelijks bestuur). Het ambtelijk apparaat bestaat uit de ministeries en de agentschappen (uitvoering). Naast deze kernorganisaties zijn er een aantal zelfstandige bestuursorganen die taken uitvoeren binnen het landsbestuur.',
                                },
                                {
                                    title: 'Decentraal openbaar bestuur',
                                    content: 'De provincies, gemeenten en waterschappen vormen samen het decentrale openbare bestuur. De decentrale organisaties bestaan steeds uit een bestuurlijk apparaat met een volksvertegenwoordiging en een dagelijks bestuur, en een ambtelijk apparaat. Naast deze kernorganisaties zijn er <link tag="gemeenschappelijke organisaties" href="/sectoren/gemeenschappelijkeorganisaties"></link> en <link tag="maatschappelijke organisaties" href="/sectoren/maatschappelijkeorganisaties"></link> die taken uitvoeren binnen het provinciale-, lokale- en of waterschapsbestuur.',
                                },
                                {
                                    title: 'Publicaties',
                                    content: '<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!'
                                }
                        ],
                },      
                {
                    id: 'provincies',
                    image: ProvinciesImage,
                    title: 'DE PROVINCIES',
                    content: [
                                { 
                                    title: 'Performanceverbetering',
                                    content: '<bureaumb></bureaumb> richt zich op de <link href="/vraagstukken/organisatiesoptimaliseren" tag="performanceverbetering"></link> van provincies en de  <link href="/sectoren/maatschappelijkeorganisaties" tag="maatschappelijke organisaties"></link> die aan hen verbonden zijn. Wij werken voor provinciale staten, het college van gedeputeerde staten, directie en management en de ambtelijke professionals. Daarnaast ondersteunen wij de provincies in de samenwerking met <link href="/sectoren/provincies" tag="provincies"></link>, <link href="/sectoren/gemeenten" tag="gemeenten"></link>, <link href="/sectoren/waterschappen" tag="waterschappen"></link>. (regelingen).'
                                },
                                {
                                    title: 'Publicaties',
                                    content: '<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!',
                                }
                        ],
                },
                {
                    id: 'gemeenten',
                    image: GemeenteImage,
                    title: 'DE GEMEENTEN',
                    content: [
                                { 
                                    title: 'Performanceverbetering',
                                    content: '<bureaumb></bureaumb> is een adviesbureau dat zich richt zich op de <link href="/vraagstukken/organisatiesoptimaliseren" tag="performanceverbetering"></link> van gemeenten en de <link href="/sectoren/maatschappelijkeorganisaties" tag="maatschappelijke organisaties"></link> die aan hen verbonden zijn. We werken voor de gemeenteraad, het college van burgemeester en wethouders, directie, management en de ambtelijke organisatie. Daarnaast ondersteunen wij de gemeenten in de samenwerking met elkaar, en met <link href="/sectoren/provincies" tag="provincies"></link>, <link href="/sectoren/gemeenten" tag="gemeenten"></link>, <link href="/sectoren/waterschappen" tag="waterschappen"></link> (regelingen).'
                                },
                                {
                                    title: 'Publicaties',
                                    content: '<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!'
                                }
                            ],
                },
                {
                    id: 'waterschappen',
                    image: WaterschappeInmage,
                    title: 'DE WATERSCHAPPEN',
                    content: [
                                { 
                                    title: 'Performanceverbetering',
                                    content: '<bureaumb></bureaumb> richt zich op de <link href="/vraagstukken/organisatiesoptimaliseren" tag="performanceverbetering"></link> van waterschappen. We werken voor algemeen bestuur, dagelijks bestuur, directie, management en ambtelijke organisatie. Daarnaast ondersteunen wij waterschappen in de samenwerking met elkaar, en met <link href="/sectoren/provincies" tag="provincies"></link>, <link href="/sectoren/gemeenten" tag="gemeenten"></link>, <link href="/sectoren/waterschappen" tag="waterschappen"></link> (regelingen).',
                                },
                                {
                                    title: 'Publicaties',
                                    content: '<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!',
                                }
                            ],
                },
                {
                    id: 'gemeenschappelijkeorganisaties',
                    image: GemeenschappelijkeorganisatiesImage,
                    title: 'DE GEMEENTEN?',
                    content: [
                                { 
                                    title: 'Performanceverbetering',
                                    content: '<bureaumb></bureaumb> richt zich op de <link href="/vraagstukken/organisatiesoptimaliseren" tag="performanceverbetering"></link> van gemeenschappelijke organisaties. Gemeenschappelijke regelingen in de vorm van een openbaar lichaam, bedrijfsvoeringsorganisatie of gemeenschappelijk orgaan. Eigenstandige publieke samenwerkingsorganisaties opgericht door combinaties van provincies, gemeenten, waterschappen en andere overheidsorganen. We werken voor algemeen bestuur, dagelijks bestuur, directie, management en ambtelijke organisatie. Daarnaast ondersteunen wij gemeenschappelijke organisaties in de samenwerking met <link href="/sectoren/provincies" tag="provincies"></link>, <link href="/sectoren/gemeenten" tag="gemeenten"></link>, <link href="/sectoren/waterschappen" tag="waterschappen"></link>.'
                                },
                                {
                                    title: 'Publicaties',
                                    content: '<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!'
                                }
                            ],
                },
                {
                    id: 'maatschappelijkeorganisaties',
                    image: MaatschappelijkeorganisatiesImage,
                    title: 'DE MAATSCHAPPELIJKE ORGANISATIES',
                    content: [
                                { 
                                    title: 'Performanceverbetering',
                                    content: '<bureaumb></bureaumb> richt zich op de <link href="/vraagstukken/organisatiesoptimaliseren" tag="performanceverbetering"></link> van maatschappelijke organisaties. Eigenstandige publieke organisaties zoals politieke partijen, vakbonden, belangenorganisaties, woningcorporaties, culturele instellingen, welzijnsinstellingen, zorginstellingen, onderwijsinstellingen, vrijwilligerscentrales, en sportverenigingen. We werken voor algemeen bestuur, dagelijks bestuur, directie, management en uitvoeringsorganisatie. Daarnaast ondersteunen wij <link href="/sectoren/maatschappelijkeorganisaties" tag="maatschappelijke organisaties"></link> in de samenwerking met het rijk, <link href="/sectoren/provincies" tag="provincies"></link> en <link href="/sectoren/gemeenten" tag="gemeenten"></link>.'
                                },
                                {
                                    title: 'Publicaties',
                                    content: '<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!'
                                }
                            ],
                },
                ]
    };
    }
    
    componentWillMount(){
        console.log(this.state.items[0].image)
    }

    render() { 
            if(this.state.items.filter(x => x.id === this.props.match.params.id)){
                return (
                    <Container className={styles.container}>
                                            {this.state.items.map(item => {
                            if(item.id === this.props.match.params.id)
                                return  (
                    <Row>
                        <Col>
                            <img
                                className={styles.image}
                                src={item.image}
                                alt="Een foto van de pronvincie met uitleg over hoe een provincie werkt"
                                    />
                        </Col>
                        <Col>

                                    <div key={item.key}>
                                    <div className={styles.title}>{item.title}</div> 
                                    <br />

                                    {item.content.map((subItem, index) => (
                                        <div key={index}>
                                            <h2 className={styles.subTitle}>{subItem.title}</h2>

                                            {/* <div className={styles.content}>{subItem.content.split('_n_').map((item, key) => {
                                                return <span key={key}>{item}<br /></span>
                                            })}</div> */}

                                            <div className={styles.content}>
                                                <span>{reactHtmlReplace(
                                                    subItem.content,
                                                    (tag, props) => {
                                                        if (tag === 'nl') {
                                                            return <div className={styles.newLine} />;
                                                        }
                                                        if (tag === 'p') {
                                                            return <p />;
                                                        }
                                                        if (tag === 'italic') {
                                                            return <i />;
                                                        }
                                                        if (tag === 'bureaumb') {
                                                            return <span>Bureau<span className={styles.bureaumb}>MB</span></span>;
                                                        }
                                                        if (tag === 'link') {
                                                            const { tag, href, style } = props;
                                                            return <a href={'#' + href} className={style}>{`${tag}`}</a>;
                                                        }
                                                    }
                                                    )}<br /></span>
                                        </div>
                                            <br />

                                        </div>

                                    ))}
                                    </div>
                                    

                        </Col>
                    </Row>
                    )})}
                    </Container>
                );
            }else{
                return (
                    <Container fluid="md">
                    <Row>
                        <Col lg={true} >
                        <div>U heeft een foute URL opgegeven. Klik op het BureaMB Logo om terug te gaan.</div>
                        </Col>
                    </Row>
                    </Container>
                )
            }
    }
}
 
export default withRouter(SectorenComponent);