import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { withRouter } from 'react-router'

import AccociesImage from "./pictures/Associes.png";
import ToetredenImage from "./pictures/Toetreden.jpg";
import WatermerkImage from "./pictures/Watermerk.jpg";

import reactHtmlReplace from 'react-html-replace';


import styles from "./styles.module.css"

class BureaumbComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        items:  [           
                {
                    id: 'bureaumb',
                    image: WatermerkImage,
                    imagealt: 'ONS NETWERK VAN ASSOCIÉS',
                    title: 'BUREAUMB, HET BUREAU',
                    content: [
                                { 
                                    title: 'Onze wortels',
                                    content: '<bureaumb></bureaumb> heeft zijn wortels en expertise in het <link tag="decentrale openbaar bestuur" href="/sectoren/sectoren"></link>. Wij bewegen mee met de opgaven in het publieke domein. Het is onze missie om <link tag="provincies" href="/sectoren/provincies"></link>, <link tag="gemeenten" href="/sectoren/gemeenten"></link>, <link tag="waterschappen" href="/sectoren/waterschappen"></link>, <link tag="gemeenschappelijk organisaties" href="/sectoren/gemeenschappelijkeorganisaties"></link> en <link tag="maatschappelijke organisaties" href="/sectoren/maatschappelijkeorganisaties"></link> doeltreffend te maken. Daardoor kunnen zij meer bijdragen aan de kwaliteit van onze samenleving.',
                                },
                                {
                                    title: "Onze werkwijze",
                                    content:
                                      "Met advies en verandermanagement brengt <bureaumb></bureaumb> de sturing, structuur, cultuur, werkwijzen en informatievoorziening van uw organisatie op het hoogst haalbare niveau zodat uw mensen optimaal worden gefaciliteerd in hun performance. Daarnaast zorgen wij met maatwerkopleidingen dat onze kennis van uw organisatiesystemen wordt overgedragen op uw mensen!",
                                  },
                                {
                                    title: 'Persoonlijk in het contact',
                                    content: 'Met <bureaumb></bureaumb> maakt werk van de persoonlijke ontwikkeling van haar associés door samen met hen actief bezig te zijn met hun loopbaan als zelfstandig professional. In het verlengde hiervan bieden wij een uitgekiend opleidingsprogramma dat aansluit bij de ontwikkelingen in de markt. Maak kennis met de mensen van <bureaumb></bureaumb>. Wij maken graag kennis met u in contact.',
                                },
                                {
                                    title: 'Onze missie',
                                    content: 'Het is onze missie om de performance van mensen en organisaties te verbeteren. <link tag="Adviseren" href="/diensten/advies"></link>, <link tag="coaching" href="/diensten/coaching"></link>, <link tag="trainen" href="/diensten/Training"></link> en <link tag="managen" href="/diensten/management"></link> zijn moderne ambachten, we gebruiken beproefde concepten en spitsen die toe op de context van uw organisatie. <bureaumb></bureaumb> is sterk in strategie bepalen,<link tag="organisaties optimaliseren" href="/vraagstukken/organisatiesoptimaliseren"></link>, <link tag="mensen professionaliseren" href="/vraagstukken/mensenprofessionaliseren"></link> en <link tag="Gemeenschappelijke organisaties inrichten" href="/vraagstukken/Gemeenschappelijkeorganisaties"></link>. Wij brengen structuur in het proces en energie bij de mensen. Maak kennis met de mensen van <bureaumb></bureaumb>. Wij maken graag kennis met u.',
                                },
                                {
                                    title: 'Publicaties',
                                    content: '<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!'
                                }
                        ],
                },    
                {
                    id: 'associes',
                    image: AccociesImage,
                    imagealt: 'ONS NETWERK VAN ASSOCIÉS',
                    title: 'BUREAUMBEN ONS NETWERK VAN ASSOCIÉS',
                    content: [
                                { 
                                    title: 'Betekenisvolle relaties',
                                    content: '<bureaumb></bureaumb> gaat een persoonlijke en betekenisvolle relatie aan met haar associés. Bij ons kom je niet ‘in het bestand’ of erger ‘in de bak’, je bent als professional onderdeel van ons collectief (collectie). Wij volgen het pad van opdrachten en de persoonlijke groei van onze associés, en zijn steeds bereid om mee te denken en een bijdrage te leveren. Dit betekent dat we regelmatig contact met hen leggen om hun persoonlijke situatie en toekomstperspectief door te spreken. Daarnaast nodigen wij hen regelmatig uit om in collectief verband van gedachte te wisselen over actuele onderwerpen, houden hen op de hoogte van de ontwikkelingen binnen ons bedrijf met onze nieuwsbrief en website.',
                                },
                                {
                                    title: 'Persoonlijke ontwikkeling',
                                    content: '<bureaumb></bureaumb> maakt werk van de persoonlijke ontwikkeling van haar associés door samen met hen actief bezig te zijn met hun loopbaan als zelfstandig professional. In het verlengde hiervan bieden wij een uitgekiend opleidingsprogramma dat aansluit bij de ontwikkelingen in de markt. Maak kennis met de mensen van <bureaumb></bureaumb>. Wij maken graag kennis met u in contact.',
                                },
                        ],
                },
                {
                    id: 'toetreden',
                    image: ToetredenImage,
                    title: 'TOETREDEN TOT ONS NETWERK',
                    content: [
                                { 
                                    title: 'Stuur ons je CV',
                                    content: 'Ben je werkzaam in de decentrale openbaar bestuur en heb je belangstelling om toe te treden tot onze organisatie stuur ons dan je cv.',
                                },
                                {
                                    title: 'Wie zoeken wij',
                                    content: 'Wij zijn op zoek naar mensen die boven zichzelf weten uit te stijgen, mensen die iets bijzonders meebrengen, mensen die buiten de platgetreden paden durven te lopen, mensen waar anderen graag bij in de buurt willen zijn.',
                                },
                                {
                                    title: 'Opleiding en ervaring',
                                    content: '- Heb jij een academisch werk en denkniveau <nl> - Heb jij een brede algemene ontwikkeling </nl><nl> - Heb je minimaal 15 jaar werkervaring </nl><nl>  - Waarvan minimaal 5 jaar binnen het decentrale openbare bestuur </nl><nl>  - Waarvan minimaal 5 jaar als manager, beleidsprofessional, consultant, trainer of coach </nl>',
                                },
                        ],
                },
            ]
    };
    }


    render() { 
            if(this.state.items.filter(x => x.id === this.props.match.params.id)){
                return (
                    <Container className={styles.container}>
                        {this.state.items.map(item => {
                            if(item.id === this.props.match.params.id)
                                return  (
                    <Row>
                        <Col>
                            <img
                                className={styles.image}
                                src={item.image}
                                alt={item.imagealt}
                                    />
                        </Col>
                        <Col>

                                    <div key={item.key}>
                                    <div className={styles.title}>{item.title}</div> 
                                    <br />

                                    {item.content.map((subItem, index) => (
                                        <div key={index}>
                                            <h2 className={styles.subTitle}>{subItem.title}</h2>

                                            {/* <div className={styles.content}>{subItem.content.split('_n_').map((item, key) => {
                                                return <span key={key}>{item}<br /></span>
                                            })}</div> */}

                                            <div className={styles.content}>
                                                <span>{reactHtmlReplace(
                                                    subItem.content,
                                                    (tag, props) => {
                                                        if (tag === 'nl') {
                                                            return <div className={styles.newLine} />;
                                                        }
                                                        if (tag === 'p') {
                                                            return <p />;
                                                        }
                                                        if (tag === 'bureaumb') {
                                                            return <span>Bureau<span className={styles.bureaumb}>MB</span></span>;
                                                        }
                                                        if (tag === 'link') {
                                                            const { tag, href, style } = props;
                                                            return <a href={'#' + href} className={style}>{`${tag}`}</a>;
                                                        }
                                                    }
                                                    )}<br /></span>
                                        </div>
                                            <br />

                                        </div>

                                    ))}
                                    </div>
                                    

                        </Col>
                    </Row>
                    )})}
                    </Container>
                );
            }else{
                return (
                    <NoState />
                )
            }
    }
}
 
function NoState(){
    return (
        <Container fluid="md">
        <Row>
            <Col lg={true} >
            <div>U heeft een foute URL opgegeven. Klik op het BureaMB Logo om terug te gaan.</div>
            </Col>
        </Row>
        </Container>
    )
}

export default withRouter(BureaumbComponent);