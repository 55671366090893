import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import Collegebestuur from './pictures/collegebestuur.png';
import styles from "./styles.module.css";

class ArticleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        return (
        <Container >
            {/* <Row className="justify-content-md-center">

            <Card style={{ width: '24rem' }}>
                <Card.Img variant="top" src={Collegebestuur} className={styles.picture} />
                <Card.Body>
                    <Card.Title>College aan het stuur</Card.Title>
                    <Card.Text>
                    <div>Hoe zorgen colleges vier jaar lang voor de kaliteit van hun gemeentebestuur?</div>
                    <div>Hoe blijven zij verbonden met de samenleving, het maatschappelijk middenveld en het bedrijfsleven?</div>
                    <div>En hoe houden zij de onderlinge samenwerking en de samenwerking met de gemeenteraad en de ambtelijke organistie gezond?</div>
                    </Card.Text>
                    <Button variant="primary">Lees meer</Button>
                </Card.Body>
                </Card>
            </Row> */}
        </Container>);
    }
}
 
export default ArticleComponent;