import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'

import styles from "./styles.module.css"


class FooterComponent extends React.Component {

    render() { 
        return (  
        <div className={styles.footer}>
            <span className={styles.text}>
                © BureauMB 2021 - 'Werkt met de werkelijkheid'
            </span>
        </div>
        );
    }
}
 

export default FooterComponent;