import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { withRouter } from "react-router";

import AdviesImage from "./pictures/Advies.jpg";
import CoachingImage from "./pictures/Coaching.jpg";
import TeamcoachingImage from "./pictures/Teamcoaching.jpg";
import TrainingImage from "./pictures/Training.jpg";
import VerandermanagementImage from "./pictures/Verandermanagement.jpg";
import DienstenImage from "./pictures/Diensten.jpg";

import reactHtmlReplace from "react-html-replace";

import styles from "./styles.module.css";

class DienstenComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          id: "diensten",
          image: DienstenImage,
          imagealt: "Een foto met advies",
          title: "DE DIENSTEN",
          content: [
            {
              title: "Expertise",
              content:
                '<bureaumb></bureaumb>  is een organisatie adviesbureau dat gespecialiseerd is in diensten als <link tag="coaching" href="/diensten/coaching"></link>, <link tag="teamcoaching" href="/diensten/teamcoaching"></link>, <link tag="training" href="/diensten/training"></link> en <link tag="verandermanagement" href="/diensten/management"></link>. Wij werken met individuen, groepen of breed door de organisatie. Onze dienstverlening draait om performanceverbetering en bestaat voor een belangrijk deel uit maatwerk. Onze gelaagde aanpak.',
            },
            {
              title: "Persoonlijke ontwikkeling",
              content:
                "De eerste laag van een presterende organisatie wordt gevormd door goed functionerende individuen. De kwaliteit van de mensen wordt bepaald door betrokkenheid, motivatie, mentaliteit, gedragsrepertoire en deskundigheid. Met coaching en training helpen wij professionals vooruit in hun werk.",
            },
            {
              title: "Teamontwikkeling",
              content:
                "De tweede laag wordt gevormd door goed functionerende teams. Hun kwaliteit wordt bepaald door sturing, samenwerking, groepscultuur, sociaal verkeer en elkaar aanvullende deskundigheid. Met teamcoaching en training zorgen wij ervoor dat teams tot bloei komen.",
            },
            {
              title: "Organisatieontwikkeling",
              content:
                'De derde laag wordt gevormd door uitgewerkte organisatiesystemen en een gezond uitdagend werkklimaat. Kwaliteit wordt bepaald door <link tag="training" href="/diensten/training"></link>, ,<link href="/diensten/management" tag="management"></link> organisatiestructuur, organisatiecultuur, werkprocessen, informatievoorziening, personeel en middelen. Met advies, training en verandermanagement maken wij van gewone organisaties, toporganisaties.',
            },
          ],
        },
        {
          id: "advies",
          image: AdviesImage,
          imagealt: "Een foto met advies",
          title: "ADVIES",
          content: [
            {
              title: "Organisatieadvies",
              content:
                'Organisatieadvies is de kern van onze dienstverlening. Daarnaast zijn wij gespecialiseerd in <link href="/diensten/coaching" tag="coaching"></link>, <link href="/diensten/teamcoaching" tag="teamcoaching"></link>, <link href="/diensten/training" tag="training"></link> en <link href="/diensten/management" tag="management"></link>. Onze adviseurs kijken altijd naar uw organisatie in zijn context, voordat zij een aanpak voorstellen. Elk adviestraject sluit aan bij uw ambities en bij de potentie van uw organisatie.',
            },
            {
              title: "Werkwijze",
              content:
                '<bureaumb></bureaumb> ziet uw organisatie als een samenhangend systeem. We kijken naar leiderschap, structuur, cultuur, werkprocessen, informatievoorziening, personeel en middelen. Na een analyse maakt BureauMB een plan om belemmeringen weg te nemen en resultaten te boeken. We zetten in op strategieontwikkeling, <link href="diensten/management/" tag="managementontwikkeling"></link>, organisatieontwikkeling en cultuurontwikkeling. Alles dat noodzakelijk is om te komen tot de gewenste performanceverbetering.',
            },
            {
              title: "Expertise",
              content:
                "Een organisatieadvies van <bureaumb></bureaumb> verdwijnt niet in de la. Onze expertise van bestuurskunde, organisatiekunde en organisatiesociologie komt samen in veranderprocessen die slagen. In trajecten waar u zelf aan het stuur zit en uw mensen een actieve rol spelen.",
            },
            {
              title: "Publicaties",
              content:
                '<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw  <link href="/vraagstukken/vraagstukken" tag="vraagstukken"></link> onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!',
            },
          ],
        },
        {
          id: "coaching",
          image: CoachingImage,
          imagealt: "Een foto met advies",
          title: "COACHING",
          content: [
            {
              title: "Coaching",
              content:
                'Coaching is de kern binnen onze dienstverlening als het gaat om persoonlijke ontwikkeling. Naast coaching zijn wij ook gespecialiseerd in <link href="/diensten/advies" tag="advies"></link>, <link href="/diensten/teamcoaching" tag="teamcoaching"></link> en <link href="/diensten/management" tag="verandermanagement"></link>. Onze coaches sluiten eerst aan op uw behoeften, voordat zij een aanpak voorstellen. Elk coachingstraject sluit aan bij uw ambities en de mogelijkheden van uw organisatie.',
            },
            {
              title: "Expertise",
              content:
                "Een coachingstraject van <bureaumb></bureaumb> is mens- en praktijkgericht. Onze expertise van organisatiepsychologie, organisatiesociologie, organisatiekunde en bestuurskunde komt samen in persoonlijke ontwikkelingstrajecten die slagen. Wij brengen u verder als het gaat om persoonlijke ontwikkeling, leiderschapsontwikkeling en loopbaanontwikkeling..",
            },
            {
              title: "BureauMB publiceert",
              content:
                "<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!",
            },
          ],
        },
        {
          id: "teamcoaching",
          image: TeamcoachingImage,
          imagealt: "Een foto met advies",
          title: "TEAMCOACHING",
          content: [
            {
              title: "Teamcoaching",
              content:
                'Teamcoaching is de kern binnen onze dienstverlening als het gaat om teamontwikkeling. Naast teamcoaching zijn wij ook gespecialiseerd in <link href="/diensten/advies" tag="advies"></link>, <link href="/diensten/teamcoaching" tag="teamcoaching"></link> en <link href="/diensten/management" tag="verandermanagement"></link>. Onze teamcoaches kijken altijd naar het team en de individuen in de context van de organisatie. Elk teamcoachingstraject sluit aan bij de ambities van team en individuen, en is afgestemd op de mogelijkheden van de organisatie.',
            },
            {
              title: "Werkwijze",
              content:
                "<bureaumb></bureaumb> ziet het team als hét systeem waarlangs performanceverbetering plaatsvindt. Onze teamcoaches bieden kaders, komen met alternatieve werkwijzen en brengen nieuwe energie. We snijden thema’s aan als: teamorganisatie, mentaliteit, arbeidsgedrag, relaties, teamcultuur, sociaal verkeer, communicatieve vaardigheden, netwerken en leiderschap. Ieder gewenst thema om te komen tot",
            },
            {
              title: "Expertise",
              content:
                "Een teamcoachingstraject van <bureaumb></bureaumb> is mens- en praktijkgericht. Onze expertise van organisatiepsychologie, organisatiesociologie, organisatiekunde en bestuurskunde komt samen in teamontwikkelingstrajecten die slagen. Wij brengen u en uw team verder als het gaat om teamontwikkeling, persoonlijke ontwikkeling en leiderschapsontwikkeling.",
            },
            {
              title: "Publicaties",
              content:
                "BureauMB publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!",
            },
          ],
        },
        {
          id: "training",
          image: TrainingImage,
          imagealt: "Een foto met advies",
          title: "TRAINING",
          content: [
            {
              title: "Training",
              content:
                'Training is belangrijk binnen onze dienstverlening als het gaat om persoonlijke ontwikkeling en teamontwikkeling. Naast training zijn wij ook gespecialiseerd in <link href="/diensten/advies" tag="advies"></link>, <link href="/diensten/teamcoaching" tag="teamcoaching"></link> en <link href="/diensten/management" tag="verandermanagement"></link>. Onze trainers geven inzichten die leiden tot nieuwe competenties, nieuw arbeidsgedrag en nieuwe werkwijzen. Elk trainingsprogramma sluit aan bij de ambities van team en individuen, en is afgestemd op de mogelijkheden van de organisatie.',
            },
            {
              title: "Expertise",
              content:
                "<bureaumb></bureaumb> brengt mensen en teams verder die in zichzelf willen investeren. Onze trainers leven zich in, bieden kaders en wijzen de weg op het pad van groei. Wij leveren maatwerk met een goede intake, resultaatafspraken en evaluatie achteraf. Waar mogelijk gebruiken we modulen die hun kracht bewezen hebben, waar nodig maken we iets nieuws. Steeds met het oog op budget en performanceverbetering.",
            },
            {
              title: "Het trainingsprogramma",
              content:
                "Een trainingsprogramma van <bureaumb></bureaumb> is nooit vrijblijvend. Onze expertise van organisatiepsychologie, organisatiesociologie, organisatiekunde en bestuurskunde komt samen in trainingsprogramma’s die slagen. Wij brengen u verder als het gaat om persoonlijke ontwikkeling, leiderschapsontwikkeling en teamontwikkeling.",
            },
            {
              title: "Publicaties",
              content:
                "<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!",
            },
          ],
        },
        {
          id: "management",
          image: VerandermanagementImage,
          imagealt: "Een foto met advies",
          title: "VERANDER MANAGEMENT",
          content: [
            {
              title: "Verandermanagement",
              content:
                "Verandermanagement is een belangrijk bestanddeel van onze dienstverlening. Daarnaast zijn wij gespecialiseerd in advies, coaching, teamcoaching en training. Onze verandermanagers kijken altijd naar uw organisatie in zijn context, voordat zij een aanpak voorstellen. Elk verandertraject sluit aan bij uw ambities en bij de potentie van uw organisatie.",
            },
            {
              title: "Werkwijze",
              content:
                "<bureaumb></bureaumb> ziet uw organisatie als een samenhangend systeem. Veranderingen implementeren doen we doordacht. Op basis van programma’s en projecten realiseert BureauMB op nauwgezette wijze uw veranderdoelen. Indien wenselijk begeleiden we uw verandertrajecten met de inzet van ervaren co-managers. We zetten in op: strategie-implementatie, organisatieverandering, cultuurverandering, managementontwikkeling, teamontwikkeling en persoonlijke ontwikkeling. Alles dat noodzakelijk is om te komen tot de gewenste performanceverbetering.",
            },
            {
              title: "Expertise",
              content:
                "Een implementatieopdracht van <bureaumb></bureaumb> leidt altijd tot het gewenste resultaat. Onze expertise van bestuurskunde, organisatiekunde, organisatiesociologie en organisatiepsychologie komt samen in veranderprocessen die slagen.",
            },
            {
              title: "Publicaties",
              content:
                "<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!",
            },
          ],
        },
      ],
    };
  }

  componentWillMount() {
    console.log(this.state.items[0].image);
  }

  render() {
    if (this.state.items.filter((x) => x.id === this.props.match.params.id)) {
      return (
        <Container className={styles.container}>
          {this.state.items.map((item) => {
            if (item.id === this.props.match.params.id)
              return (
                <Row>
                  <Col>
                    <img
                      className={styles.image}
                      src={item.image}
                      alt={item.imagealt}
                    />
                  </Col>
                  <Col>
                    <div key={item.key}>
                      <div className={styles.title}>{item.title}</div>
                      <br />

                      {item.content.map((subItem, index) => (
                        <div key={index}>
                          <h2 className={styles.subTitle}>{subItem.title}</h2>

                          {/* <div className={styles.content}>{subItem.content.split('_n_').map((item, key) => {
                                                return <span key={key}>{item}<br /></span>
                                            })}</div> */}

                          <div className={styles.content}>
                            <span>
                              {reactHtmlReplace(
                                subItem.content,
                                (tag, props) => {
                                  if (tag === "nl") {
                                    return <div className={styles.newLine} />;
                                  }
                                  if (tag === "p") {
                                    return <p />;
                                  }
                                  if (tag === "italic") {
                                    return <i />;
                                  }
                                  if (tag === "bureaumb") {
                                    return (
                                      <span>
                                        Bureau
                                        <span className={styles.bureaumb}>
                                          MB
                                        </span>
                                      </span>
                                    );
                                  }
                                  if (tag === "link") {
                                    const { tag, href, style } = props;
                                    return (
                                      <a
                                        href={"#" + href}
                                        className={style}
                                      >{`${tag}`}</a>
                                    );
                                  }
                                }
                              )}
                              <br />
                            </span>
                          </div>
                          <br />
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              );
          })}
        </Container>
      );
    } else {
      return (
        <Container fluid="md">
          <Row>
            <Col lg={true}>
              <div>
                U heeft een foute URL opgegeven. Klik op het BureaMB Logo om
                terug te gaan.
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default withRouter(DienstenComponent);
