import React from 'react';
import Container from 'react-bootstrap/Container'
import FabComponent from '../fab/Fab';

import styles from './styles.module.css';


class AppbarComponent extends React.Component {

    render() { 
        return (  
        <div>
            <div className={styles.navbar}>
                    <Container className="justify-content-md-center">
                    <FabComponent className={styles.fab}/>
                    <span className={styles.bold}>BureauMB</span><span className={styles.floatLeft}> - 'Bouwt bruggen tussen mensen'</span>
                    </Container>
            </div>
        </div>
        );
    }
}
 

export default AppbarComponent ;