import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { withRouter } from "react-router";

import reactHtmlReplace from "react-html-replace";

import StrategieImage from "./pictures/Strategie.jpg";
import GemeenschappelijkeOrganisatiesInrichtenImage from "./pictures/GemeenschappelijkeOrganisatiesInrichten.jpg";
import MensenProfessionaliserenImage from "./pictures/MensenProfessionaliseren.jpg";
import OrganisatiesOptimaliserenImage from "./pictures/OrganisatiesOptimaliseren.jpg";
import VraagstukkenImage from "./pictures/Vraagstukken.png";

import styles from "./styles.module.css";

class VraagstukkenComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          id: "vraagstukken",
          image: VraagstukkenImage,
          title: "KLANTVRAAGSTUKKEN",
          content: [
            {
              title: "Vraagstukken",
              content:
                'Uw vraagstukken staan voor <link href="/bureaumb/bureaumb" tag="BureauMB"></link> altijd centraal. We onderscheiden vier categorieën van klantvraagstukken: <link tag="strategie bepalen" href="/vraagstukken/strategiebepalen"></link>, <link tag="organisaties optimaliseren" href="/vraagstukken/organisatiesoptimaliseren"></link>, <link tag="mensen professionaliseren" href="/vraagstukken/mensenprofessionaliseren"></link> en <link tag="gemeenschappelijkeorganisatiesinrichten" href="/vraagstukken/gemeenschappelijkeorganisatiesinrichten"></link> inrichten.',
            },
            {
              title: "Andergericht en vraaggestuurd werken",
              content:
                "Onze mensen zijn zich er van bewust dat ontwikkelings- en veranderingswerk om u en uw mensen draait. Ons referentiekader bij opdrachten bestaat uit uw uitgangspunten, doelen en verwachtingen. Deze andergerichte manier van werken, kenmerkt zich door een oprechte belangstelling voor de mensen waarmee wij werken. We stellen daarom veel open vragen, luisteren actief en bewegen mee waar dat kan. Zo ontdekken we uw echte vragen en behoeften en doen we juist die dingen waar u op zit te wachten.",
            },
            {
              title: "Onze organische en gestructureerde werkwijze",
              content:
                "We weten dat ontwikkelings- en veranderingswerk niet altijd even goed te plannen is. Omstandigheden wijzigen, besluitvorming laat op zich wachten, werkzaamheden vallen tegen, mensen hebben meer tijd nodig. Zo zijn er legio obstakels die overwonnen moeten worden. Ieder traject heeft zijn uitdagingen. Daarom acteren wij zo strak mogelijk op de gestelde doelen, hanteren we degelijke planningen waarin we niet te ver vooruit plannen, monitoren scherp de uitvoering, faciliteren de mensen die het moeten doen en creëren ruimte waar dat nodig is. Kortom we zijn vriendelijk in het contact maar zetten druk op de resultaten.",
            },
            {
              title: "BureauMB publiceert",
              content:
                "<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!",
            },
          ],
        },
        {
          id: "strategiebepalen",
          image: StrategieImage,
          title: "STRATEGIE BEPALEN",
          content: [
            {
              title: "Strategie bepalen",
              content:
                'Strategie bepalen is de eerste categorie van klantvraagstukken waar <bureaumb></bureaumb> zich mee bezighoudt. De andere categorieën zijn <link href="/vraagstukken/organisatiesoptimaliseren" tag="organisaties optimaliseren"></link>, <link href="/vraagstukken/mensenprofessionaliseren" tag="mensen professionaliseren"></link> en <link href="/vraagstukken/gemeenschappelijkeorganisatiesinrichten" tag="gemeenschappelijke organisaties inrichten."></link>',
            },
            {
              title: "Onderzoek en advies",
              content:
                'Met onderzoek en <link href="/diensten/advies" tag="advies"></link> ondersteunt <bureaumb></bureaumb> u met het bepalen van uw strategie. Wij helpen u met het vinden van de juiste richting in een complexe en snel veranderende omgeving. Daarnaast kunnen wij u en uw uitvoeringsorganisatie ondersteunen met implementatiemanagement om de uitgestippelde koers ook daadwerkelijk te realiseren.',
            },
            {
              title: "BureauMB publiceert",
              content:
                "<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155",
            },
          ],
        },
        {
          id: "organisatiesoptimaliseren",
          image: OrganisatiesOptimaliserenImage,
          title: "ORGANISATIES OPTIMALISEREN",
          content: [
            {
              title: "Organisaties optimaliseren",
              content:
                'Organisaties optimaliseren is de tweede categorie van klantvraagstukken waar <bureaumb></bureaumb> zich mee bezighoudt. De andere categorieën zijn <link href="/vraagstukken/organisatiesoptimaliseren" tag="organisaties optimaliseren"></link>, <link href="/vraagstukken/mensenprofessionaliseren" tag="mensen professionaliseren"></link> en <link href="/vraagstukken/gemeenschappelijkeorganisatiesinrichten" tag="gemeenschappelijke organisaties inrichten."></link>',
            },
            {
              title: "Onze werkwijze",
              content:
                'Met <link href="diensten/advies" tag="advies"></link> en <link href="/diensten/management" tag="verandermanagement"></link> brengt <bureaumb></bureaumb> de sturing, structuur, cultuur, werkwijzen en informatievoorziening van uw organisatie op het hoogst haalbare niveau zodat uw mensen optimaal worden gefaciliteerd in hun performance. Daarnaast zorgen wij met maatwerkopleidingen dat onze kennis van uw organisatiesystemen wordt overgedragen op uw mensen!',
            },
            {
              title: "BureauMB publiceert",
              content:
                "<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!",
            },
          ],
        },
        {
          id: "mensenprofessionaliseren",
          image: MensenProfessionaliserenImage,
          title: "MENSEN PROFESSIONALISEREN",
          content: [
            {
              title: "Mensen professionaliseren",
              content:
                'Mensen professionaliseren is de derde categorie van klantvraagstukken waar <bureaumb></bureaumb> zich mee bezighoudt. De andere categorieën zijn <link href="/vraagstukken/organisatiesoptimaliseren" tag="organisaties optimaliseren"></link>, <link href="/vraagstukken/mensenprofessionaliseren" tag="mensen professionaliseren"></link> en <link href="/vraagstukken/gemeenschappelijkeorganisatiesinrichten" tag="gemeenschappelijke organisaties inrichten."></link>',
            },
            {
              title: "Mensen ontwikkelen",
              content:
                'Met <link href="/diensten/coaching" tag="coaching"></link>, <link href="/diensten/teamcoaching" tag="teamcoaching"></link> en <link href="/diensten/training" tag="training"></link> helpt <bureaumb></bureaumb> wensen bij hun persoonlijke ontwikkeling en de ontwikkeling van het team waarin zij werken. We bieden modules aan die zich richten op het individu zoals: zelfbewustzijn, mentale vaardigheden, mentaliteit, gedragsstijlen, zelfsturing, zelforganisatie, beïnvloeden en communicatie. Daarnaast bieden we modules aan voor teams zoals: arbeidsrelaties, groepsdynamiek, groepsmentaliteit, omgangsvormen, teamontwikkeling en leiderschap.',
            },
            {
              title: "BureauMB publiceert",
              content:
                "<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155!",
            },
          ],
        },
        {
          id: "gemeenschappelijkeorganisatiesinrichten",
          image: GemeenschappelijkeOrganisatiesInrichtenImage,
          title: "GEMEENSCHAPPELIJKE ORGANISATIES INRICHTEN",
          content: [
            {
              title: "De gemeenschappelijke organisatie inrichten",
              content:
                'Gemeenschappelijke organisaties inrichten is de vierde categorie van klantvraagstukken waar <bureaumb></bureaumb> zich mee bezighoudt. De andere categorieën zijn <link href="/vraagstukken/organisatiesoptimaliseren" tag="organisaties optimaliseren"></link>, <link href="/vraagstukken/mensenprofessionaliseren" tag="mensen professionaliseren"></link> en <link href="/vraagstukken/gemeenschappelijkeorganisatiesinrichten" tag="gemeenschappelijke organisaties inrichten."></link>',
            },
            {
              title: "Onze werkwijze",
              content:
                'Bij het inrichten van gemeenschappelijke organisaties komen de drie categorieën vraagstukken: strategie bepalen organisatie optimaliseren en mensen professionaliseren bij elkaar. Met <link href="/diensten/advies" tag="advies"></link>, <link href="/diensten/management" tag="verandermanagement"></link>, <link href="diensten/coaching" tag="coaching"></link>, <link href="/diensten/teamcoaching" tag="teamcoaching"></link> en <link href="/diensten/training" tag="training"></link> zorgen wij dat een <link href="/sectoren/gemeenschappelijkeorganisaties" tag="gemeenschappelijke organisaties"></link> draagvlak vindt bij haar moederorganisaties en goed functionerend van start gaat.',
            },
            {
              title: "Publicaties",
              content:
                "<bureaumb></bureaumb> publiceert regelmatig interessante artikelen over het decentrale binnenlandse bestuur. Uw vraagstukken onze diensten. Bel voor antwoorden met Marcel Bouman 06-30363155",
            },
          ],
        },
      ],
    };
  }

  componentWillMount() {
    console.log(this.state.items[0].image);
  }

  render() {
    if (this.state.items.filter((x) => x.id === this.props.match.params.id)) {
      return (
        <Container className={styles.container}>
          {this.state.items.map((item) => {
            if (item.id === this.props.match.params.id)
              return (
                <Row>
                  <Col>
                    <img
                      className={styles.image}
                      src={item.image}
                      alt="Een foto van de pronvincie met uitleg over hoe een provincie werkt"
                    />
                  </Col>
                  <Col>
                    <div key={item.key}>
                      <div className={styles.title}>{item.title}</div>
                      <br />

                      {item.content.map((subItem, index) => (
                        <div key={index}>
                          <h2 className={styles.subTitle}>{subItem.title}</h2>

                          {/* <div className={styles.content}>{subItem.content.split('_n_').map((item, key) => {
                                                return <span key={key}>{item}<br /></span>
                                            })}</div> */}

                          <div className={styles.content}>
                            <span>
                              {reactHtmlReplace(
                                subItem.content,
                                (tag, props) => {
                                  if (tag === "nl") {
                                    return <div className={styles.newLine} />;
                                  }
                                  if (tag === "p") {
                                    return <p />;
                                  }
                                  if (tag === "italic") {
                                    return <i />;
                                  }
                                  if (tag === "bureaumb") {
                                    return (
                                      <span>
                                        Bureau
                                        <span className={styles.bureaumb}>
                                          MB
                                        </span>
                                      </span>
                                    );
                                  }
                                  if (tag === "link") {
                                    const { tag, href, style } = props;
                                    return (
                                      <a
                                        href={"#" + href}
                                        className={style}
                                      >{`${tag}`}</a>
                                    );
                                  }
                                }
                              )}
                              <br />
                            </span>
                          </div>
                          <br />
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              );
          })}
        </Container>
      );
    } else {
      return (
        <Container fluid="md">
          <Row>
            <Col lg={true}>
              <div>
                U heeft een foute URL opgegeven. Klik op het BureaMB Logo om
                terug te gaan.
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default withRouter(VraagstukkenComponent);
