import React from 'react';
import { FaCaretDown } from "react-icons/fa";
import './styles.css';

class NavbarComponent extends React.Component {
    render() { 
        return (  
            <div class="outer">
        <div class="navigation effect6">
            <ul class="nav">
              {/* publicaties */}
              <li class="padding">
                <a href="#">Publicaties</a>
              </li>
              {/* Vraagstukken */}
              <li class="padding">
              <a href="#/vraagstukken/vraagstukken">Vraagstukken<FaCaretDown class="icon"/></a>
                      <ul class="sub">
                  <li><a href="#/vraagstukken/strategiebepalen">Strategie bepalen</a></li>
                  <li><a href="#/vraagstukken/organisatiesoptimaliseren">Organisaties optimaliseren</a></li>
                  <li><a href="#/vraagstukken/mensenprofessionaliseren">Mensen professionaliseren</a></li>
                  <li><a href="#/vraagstukken/gemeenschappelijkeorganisatiesinrichten">Gemeenschappelijke organisaties inrichten</a></li>
                </ul>
              </li>
              {/* Diensten */}
              <li class="padding">
              <a href="#/diensten/diensten">Diensten<FaCaretDown class="icon"/></a>
                <ul class="sub">
                  <li><a href="#/diensten/advies">Advies</a></li>
                  <li><a href="#/diensten/coaching">Coaching</a></li>
                  <li><a href="#/diensten/teamcoaching">Teamcoaching</a></li>
                  <li><a href="#/diensten/training">Training</a></li>
                  <li><a href="#/diensten/management">Management</a></li>
                </ul>
              </li>
              {/* De sectoren */}
              <li class="padding">
                <a href="#/sectoren/sectoren">Sectoren<FaCaretDown class="icon"/></a>
                <ul class="sub">
                  <li><a href="#/sectoren/provincies">Provincies </a></li>
                  <li><a href="#/sectoren/gemeenten">Gemeenten</a></li>
                  <li><a href="#/sectoren/waterschappen">Waterschappen</a></li>
                  <li><a href="#/sectoren/gemeenschappelijkeorganisaties">Gemeenschappelijke organisaties</a></li>
                  <li><a href="#/sectoren/maatschappelijkeorganisaties">Maatschappelijke organisaties</a></li>
                </ul>
              </li>
              {/* BureauMB */}
              <li class="padding">
              <a href="#/bureaumb/bureaumb">BureauMB<FaCaretDown class="icon"/></a>
                <ul class="sub">
                  <li><a href="#/bureaumb/bureaumb">Over BureauMB</a></li>
                  {/* <li><a href="#/bureaumb/associes">Associés</a></li>
                  <li><a href="#/bureaumb/toetreden">Toetreden</a></li> */}
                </ul>
              </li>
              {/* Contact */}
              <li class="padding">
              <a href="#/contact/contact">Contact</a></li>
            </ul>
          </div>
          <div class="footer"></div>
        </div>
        );
    }
}
 

export default NavbarComponent;